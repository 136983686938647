import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { getExperiences } from '../../apis/CandidateAPIs';
import { fetchGeoLocation } from '../../apis/geoLocationAPI';
import BaseButton from '../atoms/BaseButton';
import CustomCheckbox from '../atoms/CustomCheckbox';
import LocationComponent from '../atoms/LocationComponent';
import RangeSliderCustom from './RangeSliderCustom';

const CandidateFilterCard = ({
	queryParams,
	page,
	queryLists,
	setTabValue,
	fetchAllCandidates,
	jobUUid,
	id,
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const formikRef = useRef();
	const [selectedExperiences, setSelectedExperiences] = useState('');
	const [salaryRange, setSalaryRange] = useState([10, 80]);
	const [salaryRangeInput, setSalaryRangeInput] = useState([]);
	const { data: geoLocationData, refetch: geoLocationRefetch } = useQuery(
		'geoLocation',
		fetchGeoLocation,
	);

	const { data: experienceLevelList } = useQuery('experienceList', getExperiences);

	const allExperiences = selectedExperiences?.length && selectedExperiences?.join(' ');
	const experience_number = allExperiences?.length && allExperiences?.match(/\d+/g).map(Number);
	const minExperience = experience_number?.length && Math.min(...experience_number);
	const maxExperience = experience_number?.length && Math.max(...experience_number);

	const initialValues = () => {
		return {
			location: `${geoLocationData?.district?.length > 0 ? `${geoLocationData?.lat},` : ''}${
				geoLocationData?.long
			}, ${geoLocationData?.country_name}`,
			experience: [],
			radius: 5,
			minSalary: salaryRange[0],
			maxSalary: salaryRange[1],
		};
	};

	const submitFormHandler = () => {
		const data = {
			q: queryParams?.q ? queryParams?.q : '',
			lat: geoLocationData?.latitude ? geoLocationData?.latitude : '',
			lon: geoLocationData?.longitude ? geoLocationData?.longitude : '',
			experience__gte: minExperience || '',
			radius: 5,
			experience__lte: maxExperience || '',
			salary__gte: salaryRangeInput[0],
			salary__lte: salaryRangeInput[1],
		};

		// eslint-disable-next-line no-param-reassign
		queryLists = { ...data };
		const queryParamsList = new URLSearchParams(queryLists).toString();

		if (page === 'job-search') {
			navigate(`job-search/?${queryParamsList}`);
		}

		if (page === 'favorite') {
			navigate(`/favorites/?${queryParamsList}`);
		}
		if (page === 'allCandiates') {
			navigate(`/job-posts/${jobUUid}/?id=${id}&${queryParamsList}`);
			fetchAllCandidates();
		}
		if (page === 'selectedCandiates') {
			navigate(`/job-posts/${jobUUid}/?id=${id}&${queryParamsList}`);
			setTabValue('2');
			fetchAllCandidates();
		}
	};

	useEffect(() => {
		if (geoLocationData) {
			if (formikRef.current) {
				formikRef.current.setFieldValue(
					'location',
					`${geoLocationData?.district?.length > 0 ? `${geoLocationData?.district},` : ''}${
						geoLocationData?.city
					}, ${geoLocationData?.country_name}`,
				);
			}
		}
	}, [geoLocationData]);

	return (
		<Box
			className="candidate-filter-card"
			sx={{ width: { lg: '450px', md: '450px', sx: '300px', xs: '300px' }, padding: '30px' }}
		>
			<Formik
				innerRef={formikRef}
				initialValues={initialValues}
				onSubmit={submitFormHandler}
				enableReinitialize
			>
				{(values, resetForm, isSubmitting) => (
					<Form>
						<h3 style={{ fontSize: '16px', color: '#24282D' }}> {t('Filter Candidates')} </h3>
						<Box>
							<LocationComponent
								name="location"
								placeholder={t('Enter Location')}
								callback={geoLocationRefetch}
								extraSx={{
									borderRadius: '44px',
								}}
								fullWidth
								errorExtra={{
									marginBottom: '0',
								}}
							/>

							{/* experience  */}
							<Box>
								<h5 style={{ fontSize: '14px', color: '#24282D' }}> {t('Experience Level')} </h5>
								<Box
									sx={{
										display: 'Grid',
										gridTemplateColumns: 'repeat(2,1fr)',
										justifyContent: 'space-between',
									}}
								>
									{experienceLevelList?.map((experience, idx) => (
										<Box
											key={experienceLevelList.uuid}
											sx={{
												marginLeft: idx % 2 !== 0 ? 'auto' : '',
												marginRight: idx === 3 ? '5px' : '',
											}}
										>
											<CustomCheckbox
												name="experience"
												// values={label}
												// setFieldValue={setFieldValue}
												selectedExperiences={selectedExperiences}
												setSelectedExperiences={setSelectedExperiences}
												// onChange={handleCheckboxChange}
												containerSx={{ color: '#8E9AA6', fontWeight: '500', fontSize: '14px' }}
												sx={{
													color: '#00807F',
													// '& input[type="checkbox"]': {
													// 	borderRadius: '7px',
													// 	border: '#00807F',
													// },
													'&.Mui-checked': {
														color: '#00807F',
													},
												}}
												value={`${experience?.range?.lower}  ${experience?.range?.upper}`}
												label={`${experience?.range?.lower} - ${experience?.range?.upper} Years`}
											/>
										</Box>
									))}
								</Box>

								<Box />
							</Box>

							{/* salary range  */}
							<Box>
								<h4 style={{ fontSize: '14px', color: '#24282D', fontFamily: 'Outfit' }}>
									{t('Salary range')}
								</h4>
								<RangeSliderCustom
									value={salaryRange}
									setValue={setSalaryRange}
									setSalaryRangeInput={setSalaryRangeInput}
								/>
							</Box>

							{/* button  */}
							<Box
								sx={{
									display: 'Grid',
									gap: '10px',
									gridTemplateColumns: {
										lg: 'repeat(2,1fr)',
										md: 'repeat(2,1fr)',
										xs: 'repeat(1,1fr)',
										sm: 'repeat(1,1fr)',
									},
								}}
							>
								<BaseButton
									type="reset"
									disabled={isSubmitting}
									onClick={() => resetForm}
									extra={{
										width: { lg: '100%', md: '100%', xs: '80%', sm: '80%' },
										color: '#214E92',
										backgroundColor: '#F4F6FA',
										fontFamily: 'Outfit',
									}}
								>
									Reset
								</BaseButton>
								<BaseButton
									type="submit"
									disabled={isSubmitting}
									extra={{
										width: { lg: '100%', md: '100%', xs: '80%', sm: '80%' },
										backgroundColor: '#214E92',
										color: 'white',
										fontFamily: 'Outfit',
										'&:hover': {
											backgroundColor: '#214E92',
											color: 'white',
										},
									}}
								>
									{t('Apply')}
								</BaseButton>
							</Box>
							<Box />
						</Box>
					</Form>
				)}
			</Formik>
		</Box>
	);
};

export default CandidateFilterCard;
