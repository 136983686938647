import { Box, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import arrowBackIcon from '../../assets/svgs/arrow_back.svg';
import useLanguage from '../../hooks/useLanguage';
import BottomGradientCircleBg from '../atoms/BottomGradientCircleBg';
import TopGradientCircleBg from '../atoms/TopGradientCircleBg';
import Logo from '../../assets/svgs/Logo';

const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
	boxShadow: 'none',
	height: '100%',
	borderRadius: '0',
	backgroundColor: 'transparent',
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	overflow: 'hidden',
}));

const AuthLayout = ({ children, backButtonText }) => {
	const { state } = useLanguage();
	const navigate = useNavigate();

	return (
		<Box
			sx={{
				flexGrow: 1,
				height: '100vh',
				overflow: 'hidden',
				'@media (max-width: 1024px)': {
					minHeight: '100vh',
					height: '100%',
				},
			}}
		>
			<Box
				spacing={0}
				height="100%"
				sx={{
					display: 'flex',
					// flexDirection: state === 'en' ? 'row' : 'row-reverse',
				}}
			>
				{/* default left side */}
				<Box
					sx={{
						width: '43.47%',
						height: '100%',
						bgcolor: '#F3F5F7',
						'@media (max-width: 1024px)': {
							display: 'none',
						},
					}}
				>
					<Item>
						{/* top back buttons */}
						{backButtonText?.length > 0 && (
							<button
								type="button"
								onClick={() => navigate(-1)}
								style={{
									zIndex: '5',
									cursor: 'pointer',
								}}
							>
								<Box
									sx={{
										position: 'absolute',
										top: '56px',
										left: state === 'en' ? '36px' : 'unset',
										right: state === 'en' ? 'unset' : '36px',
										display: 'flex',
										flexDirection: state === 'en' ? 'row' : 'row-reverse',
										alignItems: 'center',
										gap: '14px',
										color: '#24282D',
										fontWeight: '800',
										textTransform: 'capitalize',
									}}
								>
									<Box component="span" paddingTop="5px">
										<img
											src={arrowBackIcon}
											alt={backButtonText}
											style={{
												width: '24px',
												height: '24px',
												objectFit: 'cover',
												rotate: state === 'en' ? '0deg' : '180deg',
											}}
										/>
									</Box>
									{backButtonText}
								</Box>
							</button>
						)}
						{/* Top & bottom circles */}
						<div
							style={{
								position: 'absolute',
								top: '-5%',
								right: '-5%',
							}}
						>
							<TopGradientCircleBg />
						</div>
						<div
							style={{
								position: 'absolute',
								bottom: '-5%',
								left: '-5%',
							}}
						>
							<BottomGradientCircleBg />
						</div>
						{/* center logo */}
						<Logo width={220} />
					</Item>
				</Box>

				{/* right side */}
				<Box
					sx={{
						width: '56.52%',
						// paddingTop: '50px',
						paddingRight: '30px',
						minHeight: '100%',
						overflowY: 'auto',
						overflowX: 'hidden',
						backgroundColor: 'white',
						'@media (max-width: 1024px)': {
							width: '100%',
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'column',
							// paddingTop: '20px',
							paddingRight: '0px',
							overflowX: 'hidden',
						},
					}}
				>
					{children}
				</Box>
			</Box>
		</Box>
	);
};

export default AuthLayout;
