/* eslint-disable react/jsx-props-no-spreading */
import { Checkbox, FormControlLabel } from '@mui/material';
import CheckboxChecked from '../../assets/svgs/CheckboxChecked';
import CheckboxCircle from '../../assets/svgs/CheckboxCircle';

const CustomCheckbox = ({
	sx,
	containerSx,
	setSelectedExperiences,
	label,
	value,
	selectedExperiences,
	name,
}) => {
	return (
		<FormControlLabel
			label={label}
			sx={containerSx}
			name={name}
			checked={!selectedExperiences.includes(value)}
			control={
				<Checkbox
					value={value}
					onChange={() =>
						setSelectedExperiences(
							selectedExperiences.includes(value)
								? selectedExperiences.filter((val) => val !== value)
								: [...selectedExperiences, value],
						)
					}
					// onChange={() => setSelectedExperiences((oldValues) => [...oldValues, value])}
					defaultChecked
					icon={<CheckboxCircle />}
					checkedIcon={<CheckboxChecked />}
					sx={sx}
					color="success"
					// {...field}
				/>
			}
		/>
	);
};

export default CustomCheckbox;
