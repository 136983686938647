import i18next from 'i18next';
import toast from 'react-hot-toast';
import api from './config/axiosInstance';

const notificationsAPIs = {
	async fetchNotifications() {
		const response = await api.get('/notification');
		const result = response?.data;

		if (![200, 201].includes(response.status)) {
			toast.error(i18next.t('Internal server error, Please try again later.'));
		}

		return result;
	},

	async fetchHasNotifications() {
		const response = await api.get('/notification/has-notification');
		const result = response?.data;

		if (![200, 201].includes(response.status)) {
			toast.error(i18next.t('Internal server error, Please try again later.'));
		}

		return result;
	},

	async seenNotification(id) {
		const response = await api.post(`/notification/mark-as-seen/${id}`);
		const result = response?.data;

		if (![200, 201].includes(response.status)) {
			toast.error(i18next.t('Internal server error, Please try again later.'));
		}

		return result;
	},
};

export const { fetchNotifications, seenNotification, fetchHasNotifications } = notificationsAPIs;

export default notificationsAPIs;
