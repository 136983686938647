import { Box, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CustomMenuItem = ({ icon, iconSx, textSx, name, sx, handleMenuClose = '' }) => {
	const { t } = useTranslation();
	const menuName = name;

	return (
		<MenuItem onClick={handleMenuClose} sx={sx}>
			<Box sx={{ color: '#00807F', ...iconSx }}>{icon}</Box>
			<Box sx={textSx}>{t(menuName)}</Box>
		</MenuItem>
	);
};

export default CustomMenuItem;
