import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const Loading = () => {
	return (
		<Box
			sx={{
				display: 'flex',
				position: 'absolute',
				justifyContent: 'center',
				alignItems: 'center',
				top: '50%',
				left: '50%',
			}}
		>
			<CircularProgress />
		</Box>
	);
};

export default Loading;
