import axios from 'axios';
import i18next from 'i18next';
import toast from 'react-hot-toast';
import { geolocationApiKey } from './config/baseURL';

const geoLocationAPI = {
	async fetchGeoLocation() {
		const response = await axios.get(
			`https://api.ipgeolocation.io/ipgeo?apiKey=${geolocationApiKey}`,
		);
		const result = response.data;

		if (![200, 201].includes(response.status)) {
			toast.error(i18next.t('Internal server error, Please try again later.'));
		}

		return result;
	},

	async fetchCitiesByCountry(countryId, language) {
		const headers = {
			language,
		};
		try {
			const response = await axios.get(`https://api.dev.opus.sa/city/${countryId}/`, {
				headers,
			});
			const result = response.data;
			return result;
		} catch (error) {
			toast.error(i18next.t('Internal server error, Please try again later.'));
			throw error;
		}
	},
};

export const { fetchGeoLocation, fetchCitiesByCountry } = geoLocationAPI;

export default geoLocationAPI;
