const LocationMarkIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
			<g clipPath="url(#clip0_1308_19278)">
				<path
					fill="#24282D"
					d="M10 6.67a3.332 3.332 0 100 6.666 3.332 3.332 0 100-6.667zm7.45 2.5a7.494 7.494 0 00-6.617-6.617V.836H9.167v1.717A7.495 7.495 0 002.55 9.169H.833v1.667H2.55a7.495 7.495 0 006.617 6.617v1.716h1.666v-1.716a7.495 7.495 0 006.617-6.617h1.717V9.169H17.45zM10 15.835a5.829 5.829 0 01-5.833-5.833A5.829 5.829 0 0110 4.169a5.83 5.83 0 015.833 5.834A5.83 5.83 0 0110 15.836z"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1308_19278">
					<path fill="#fff" d="M0 0H20V20H0z" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default LocationMarkIcon;
