import * as yup from 'yup';

export const NewPasswordSchema = yup.object().shape({
	password: yup.string().required('This field is required'),
	// .matches(
	// 	passwordCheckRegex,
	// 	'Password must be 8 characters at minimum and should contain at least one uppercase, one lowercase and a number',
	// ),
	confirmPassword: yup
		.string()
		.required('This field is required')
		.oneOf([yup.ref('password'), null], 'Passwords do not match'),
});
