import i18next from 'i18next';
import toast from 'react-hot-toast';
import api from './config/axiosInstance';

const salaryRangeAPI = {
	async fetchSalaryRange() {
		const response = await api.get('/salary_range/');
		const result = response?.data;

		if (![200, 201].includes(response.status)) {
			toast.error(i18next.t('Internal server error, Please try again later.'));
		}

		return result;
	},
};

export const { fetchSalaryRange } = salaryRangeAPI;

export default salaryRangeAPI;
