import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import useLanguage from '../../hooks/useLanguage';
import CustomDropDown from '../atoms/CustomDropDown';
import CandidateFilterCard from './CandidateFilterCard';

const CandidateFilter = ({
	queryParams,
	setQueryParams,
	getCandidateList,
	page,
	jobUUid,
	id,
	queryLists,
	fetchAllCandidates,
	setTabValue,
}) => {
	const { state: currentLanguage } = useLanguage();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const buttonId = 'candidate-button';
	const menuId = 'candidate-menu';

	return (
		<IconButton
			size="large"
			edge="end"
			id="basic-button"
			sx={{
				position: 'absolute !important',
				right: currentLanguage === 'en' ? '23px' : 'unset',
				left: currentLanguage === 'en' ? 'unset' : '9px',
				padding: '17px',
				backgroundColor: '#F3F5F7',
				top: '2px',
			}}
			onClick={handleClick}
		>
			<FilterListIcon
				sx={{
					position: 'absolute !important',
					left: '1',
					// background: '#24282D',
					borderRadius: '23px',
					padding: '3px',
				}}
				id={buttonId}
				aria-controls={open ? menuId : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
			/>

			<CustomDropDown
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				menuId={menuId}
				buttonId={buttonId}
			>
				<CandidateFilterCard
					jobUUid={jobUUid}
					id={id}
					queryLists={queryLists}
					setTabValue={setTabValue}
					fetchAllCandidates={fetchAllCandidates}
					page={page}
					getCandidateList={getCandidateList}
					queryParams={queryParams}
					setQueryParams={setQueryParams}
				/>
			</CustomDropDown>
		</IconButton>
	);
};

export default CandidateFilter;
