import { Menu } from '@mui/material';

const CustomDropDown = ({ menuId, anchorEl, buttonId, open, onClose, children }) => {
	return (
		<Menu
			id={menuId}
			aria-labelledby={buttonId}
			anchorEl={anchorEl}
			open={open}
			onClose={onClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
		>
			{children}
		</Menu>
	);
};

export default CustomDropDown;
