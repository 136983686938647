const BottomGradientCircleBg = () => {
	return (
		<svg
			width={313}
			height={319}
			viewBox="0 0 313 319"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				opacity="0.05"
				x={-127}
				width={440}
				height={440}
				rx={220}
				fill="url(#paint0_linear_82_3114)"
			/>
			<rect
				opacity="0.05"
				x={-51}
				y={76}
				width={288}
				height={288}
				rx={144}
				fill="url(#paint1_linear_82_3114)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_82_3114"
					x1={93}
					y1={0}
					x2={93}
					y2="246.197"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#214E92" />
					<stop offset={1} stopColor="#214E92" stopOpacity={0} />
				</linearGradient>
				<linearGradient
					id="paint1_linear_82_3114"
					x1={93}
					y1={76}
					x2={93}
					y2="237.147"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#214E92" />
					<stop offset={1} stopColor="#214E92" stopOpacity={0} />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default BottomGradientCircleBg;
