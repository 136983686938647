// import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BellIcon from '../../assets/svgs/BellIcon';
import Loading from '../atoms/Loading';

const NotificationsCard = ({
	notifications,
	handleNotificationSeen,
	isLoadingNotificationIsLoading,
}) => {
	const { t } = useTranslation();

	return (
		<Box sx={{ padding: '10px 0', position: 'relative' }}>
			{isLoadingNotificationIsLoading && <Loading />}

			{notifications?.length ? (
				notifications?.map((notification) => (
					<Box
						title={t('Mark as seen')}
						onClick={() => handleNotificationSeen(notification?.id)}
						key={notification?.id}
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'start',
							cursor: 'pointer',
							transition: 'all 0.15s',
							'&:hover': {
								background: '#F3F5F7',
							},
						}}
					>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								width: '100%',
								gap: '12px',
								borderBottom: '1px solid #EFF1F3',
								padding: '25px 20px',
							}}
						>
							{notification?.icon?.length > 1 ? (
								<img
									style={{ marginTop: '6%' }}
									width={50}
									height={58}
									src={notification?.icon}
									alt=""
								/>
							) : (
								<span
									style={{
										width: '36px',
										height: '36px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										border: notification.seen ? '1px solid #EFF1F3' : '1px solid #00807F',
										borderRadius: '100%',
									}}
								>
									<BellIcon />
								</span>
							)}
							<Box>
								<h5
									style={{
										fontSize: '14px',
										marginBlockEnd: '0rem',
										color: notification.seen ? '' : '#00807F',
										margin: '0',
										fontWeight: notification.seen ? '500' : '600',
									}}
								>
									{!(document.dir === 'rtl') ? notification?.title : notification?.title_ar}
								</h5>
								<p
									style={{
										fontSize: '12px',
										color: notification.seen ? '#8E9AA6' : '#00807F',
										margin: '0',
										fontWeight: notification.seen ? '500' : '600',
									}}
								>
									{!(document.dir === 'rtl') ? notification?.text : notification?.text_ar}
								</p>
							</Box>
							<Box
								sx={{
									marginLeft: '20px',
								}}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width={20}
									height={20}
									viewBox="0 0 20 20"
									fill="none"
								>
									<path
										d="M9.99935 16.6667C10.4596 16.6667 10.8327 16.2936 10.8327 15.8333C10.8327 15.3731 10.4596 15 9.99935 15C9.53911 15 9.16602 15.3731 9.16602 15.8333C9.16602 16.2936 9.53911 16.6667 9.99935 16.6667Z"
										stroke="#8E9AA6"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M9.99935 10.8307C10.4596 10.8307 10.8327 10.4576 10.8327 9.9974C10.8327 9.53716 10.4596 9.16406 9.99935 9.16406C9.53911 9.16406 9.16602 9.53716 9.16602 9.9974C9.16602 10.4576 9.53911 10.8307 9.99935 10.8307Z"
										stroke="#8E9AA6"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M9.99935 5.0026C10.4596 5.0026 10.8327 4.62951 10.8327 4.16927C10.8327 3.70903 10.4596 3.33594 9.99935 3.33594C9.53911 3.33594 9.16602 3.70903 9.16602 4.16927C9.16602 4.62951 9.53911 5.0026 9.99935 5.0026Z"
										stroke="#8E9AA6"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</Box>
						</Box>
					</Box>
				))
			) : (
				<Box
					sx={{
						padding: '10px 30px',
					}}
				>
					{t('No notification found.')}
				</Box>
			)}
		</Box>
	);
};

export default NotificationsCard;
