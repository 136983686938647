import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { alpha, styled } from '@mui/material/styles';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import useLanguage from '../../hooks/useLanguage';
import CandidateFilter from './CandidateFilter';

const DashboardBaseInput = ({
	placeholder,
	page,
	getCandidateList,
	jobUUid,
	id,
	queryLists,
	setTabValue,
	fetchAllCandidates,
}) => {
	const { state: currentLanguage } = useLanguage();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const Search = styled('div')(({ theme }) => ({
		position: 'relative',
		borderRadius: '50px',
		border: '1px solid #EFF1F3',
		backgroundColor: alpha(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: alpha(theme.palette.common.white, 0.25),
		},
		marginRight: theme.spacing(2),
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(3),
			width: 'auto',
		},
	}));

	const SearchIconWrapper = styled('div')(({ theme }) => ({
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		fontFamily: 'Outfit',
		display: 'flex',
		alignItems: 'center',
		color: '#8E9AA6',
		justifyContent: 'center',
	}));

	const StyledInputBase = styled(InputBase)(({ theme }) => ({
		color: 'inherit',
		'& .MuiInputBase-input': {
			padding: theme.spacing(1, 1, 1, 0),
			// vertical padding + font size from searchIcon
			paddingLeft: currentLanguage === 'en' ? `calc(1em + ${theme.spacing(4)})` : 0,
			paddingRight: currentLanguage === 'en' ? 0 : `calc(1em + ${theme.spacing(4)})`,
			transition: theme.transitions.create('width'),
			color: '#8E9AA6',
			fontFamily: 'Outfit',
			// width: '300px',
			// [theme.breakpoints.up('md')]: {
			// 	// width: '200px',
			// 	width: '300%',
			// },
			// [theme.breakpoints.up('xs')]: {
			// 	width: '200px',
			// 	// width: '100%',
			// },
		},
	}));

	const handleSubmit = (values) => {
		const params = {
			q: values?.searchField,
		};

		// eslint-disable-next-line no-param-reassign
		queryLists = { ...params };
		const queryparams = new URLSearchParams(queryLists).toString('');

		if (page === 'job-search') {
			if (values?.searchField !== '') {
				navigate(`job-search/?${queryparams}`);
			}
		}

		if (page === 'favorite') {
			navigate(`/favorites/?${queryparams}`);
			// getCandidateList();
		}
		if (page === 'allCandiates') {
			navigate(`/job-posts/${jobUUid}/?id=${id}&${queryparams}`);
			fetchAllCandidates();
		}
		if (page === 'selectedCandiates') {
			navigate(`/job-posts/${jobUUid}/?id=${id}&${queryparams}`);
			setTabValue('2');
			fetchAllCandidates();
		}
	};
	return (
		<div>
			<Search>
				<SearchIconWrapper>
					<SearchIcon />
				</SearchIconWrapper>
				<Formik initialValues={{ searchField: '' }} onSubmit={handleSubmit}>
					{({ values, handleChange, handleBlur }) => (
						<Form>
							<StyledInputBase
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.searchField}
								name="searchField"
								sx={{
									width: '100%',
								}}
								placeholder={t(placeholder)}
								inputProps={{ 'aria-label': 'search' }}
							/>
						</Form>
					)}
				</Formik>

				<CandidateFilter
					fetchAllCandidates={fetchAllCandidates}
					page={page}
					queryLists={queryLists}
					setTabValue={setTabValue}
					jobUUid={jobUUid}
					id={id}
					getCandidateList={getCandidateList}
				/>
			</Search>
		</div>
	);
};

export default DashboardBaseInput;
