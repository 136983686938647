import { Box, Grid } from '@mui/material';
import { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Loading from '../components/atoms/Loading';
import useAuth from '../hooks/useAuth';
import useLanguage from '../hooks/useLanguage';
import { changeLanguage } from '../reducers/languageReducer';
import routeConfig from '../routes/routeConfig';
import routes from '../routes/routes';
import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';

const BaseLayout = () => {
	const { state: isAuthenticated } = useAuth();
	const { state: currentLanguage, dispatch } = useLanguage();
	const { i18n } = useTranslation();

	useEffect(() => {
		const lang = localStorage.getItem('language');
		if (lang === 'ar') {
			document.dir = 'rtl';
			dispatch(changeLanguage('ar'));
			i18n?.changeLanguage('ar');
		} else {
			document.dir = 'ltr';
			dispatch(changeLanguage('en'));
			i18n?.changeLanguage('en');
		}
		// eslint-disable-next-line
	}, []);

	return (
		<div>
			{isAuthenticated ? (
				<Grid container>
					<Grid
						item
						sx={{
							position: 'fixed',
							height: '100px',
							width: '100vw',
							// backgroundColor: 'white',
							zIndex: '9',
						}}
						xs={12}
						lg={12}
					>
						<Header />
					</Grid>
					<Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
						<Box
							sx={{
								position: 'fixed',
								width: '250px',
								minHeight: 'calc(100vh - 100px)',
								top: '80px',
								backgroundColor: 'white',
								display: { lg: 'block', md: 'block', xs: 'none' },
							}}
						>
							<Sidebar />
						</Box>
						<Box
							sx={{
								width: {
									xl: 'calc(100vw - 250px)',
									lg: 'calc(100vw - 250px)',
									md: 'calc(100vw - 250px)',
									sm: '100vw',
									xs: '100vw',
								},
								backgroundColor: '#F3F5F7',
								minHeight: 'calc(100vh - 100px)',
								marginLeft:
									currentLanguage === 'en'
										? { xl: '250px', lg: '250px', md: '250px', xs: '0px', sm: '0px' }
										: 0,
								marginRight:
									currentLanguage === 'en'
										? 0
										: { xl: '250px', lg: '250px', md: '250px', xs: '0px', sm: '0px' },
								marginTop: '100px',
							}}
						>
							<Suspense fallback={<Loading />}>{routeConfig(routes)}</Suspense>
						</Box>
					</Box>
				</Grid>
			) : (
				<Suspense fallback={<Loading />}>{routeConfig(routes)}</Suspense>
			)}
		</div>
	);
};
export default BaseLayout;
