import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Box, Modal, Paper, Typography } from '@mui/material';

import { styled } from '@mui/material/styles';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import Basebutton from '../atoms/BaseButton';
import CustomMenuItem from '../atoms/CustomMenuItem';
import PasswordInput from './PasswordInput';

import { changePassword } from '../../apis/authAPIs';
import { NewPasswordSchema } from '../../pages/CreateNewPasswordPage/validation/index';
import { formInputStyles } from '../../utils/styles';
import Error from '../atoms/Error';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: { xs: '250px', sm: '400px', md: '618px' },
	// height: '300px',
	bgcolor: 'background.paper',
	border: 'none',
	borderRadius: '6px',
	boxShadow: 24,
	padding: '60px 30px',
	paddingBottom: '40px',
};

const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
	boxShadow: 'none',
	height: '100%',
	borderRadius: '0',
	backgroundColor: 'transparent',
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	overflow: 'hidden',
}));

const ChangePasswordModal = () => {
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [errMsg, setError] = useState([]);
	const { t } = useTranslation();
	const { mutateAsync, isLoading, isSuccess } = useMutation((data) => changePassword(data));
	const submitFormHandler = async (values, { setSubmitting }) => {
		try {
			const data = {
				new_password: values.password,
			};
			const response = await mutateAsync(data);

			if ([400, 401].includes(response?.response?.status)) {
				setError(response?.response?.data?.errors);
			}

			// if (response?.detail?.length > 0) {
			// }

			// eslint-disable-next-line no-shadow
		} catch (error) {
			setSubmitting(false);
		}
	};
	useEffect(() => {
		handleClose();
	}, [isSuccess]);

	return (
		<>
			<CustomMenuItem
				sx={{
					display: 'flex',
					fontFamily: 'Outfit',
					gap: '10px',
					color: 'black',
					fontWeight: '500',
					alignItems: 'center',
				}}
				iconSx={{ color: '#00807F' }}
				icon={
					<LockOutlinedIcon
						style={{
							marginTop: '5px',
						}}
					/>
				}
				handleMenuClose={handleOpen}
				name="Change Password"
			/>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography
						variant="h1"
						component="h1"
						sx={{
							fontSize: '20px',
							textAlign: 'center',
							fontWeight: '600',
							fontFamily: 'Outfit',
							marginBottom: '20px',
							color: '#24282D',
						}}
					>
						{t('Create new password')}
					</Typography>
					<Item>
						<Box
							sx={{
								// height: '100vh',
								width: '100%',
								paddingTop: '0',
								marginTop: '-50px',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
								'@media (max-width: 1024px)': {
									paddingTop: '40px',
									paddingLeft: '30px',
									paddingRight: '30px',
									marginTop: '0',
								},
								'@media (max-width: 330px)': {
									paddingTop: '40px',
									paddingLeft: '15px',
									paddingRight: '15px',
								},
							}}
						>
							{/* Main heading */}

							{/* form here */}
							<Formik
								initialValues={{ password: '', confirmPassword: '' }}
								validationSchema={NewPasswordSchema}
								onSubmit={submitFormHandler}
							>
								<Form
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										marginBottom: '50px',
										marginTop: '20px',
										width: '100%',
									}}
								>
									{/* {isError && ( */}
									<Box
										sx={{
											width: '480px',
											'@media (max-width: 1440px)': {
												width: '100%',
												maxWidth: '480px',
											},
										}}
									>
										{/* <Error error="error" /> */}
										{errMsg?.map((err) => (
											<Error
												key={`${err?.field} ${err?.message}`}
												error={`${err?.field} ${err?.message}`}
											/>
										))}
									</Box>
									{/* )} */}

									<PasswordInput
										sx={{
											...formInputStyles,
											marginBottom: '0px',
											'@media (min-width: 1024px)': {
												marginBottom: '0px',
											},
										}}
										placeholder="Create Password"
										name="password"
									/>
									<PasswordInput
										sx={{
											...formInputStyles,
											marginBottom: '0px',
											'@media (min-width: 1024px)': {
												marginBottom: '0px',
											},
										}}
										placeholder="Confirm Password"
										name="confirmPassword"
									/>

									<Basebutton
										type="submit"
										disabled={isLoading}
										extra={{
											background: '#214E92',
											width: { md: '320px', xs: '200px' },
											padding: '12px 24px',
											color: 'white',
											marginTop: '50px',
											'&:hover': {
												background: '#11356b',
											},
											'@media (max-width: 1024px)': {
												marginTop: '15px',
												width: '100%',
											},
										}}
									>
										{t('Update')}
									</Basebutton>
								</Form>
							</Formik>
							{/* Form end */}
						</Box>
					</Item>
				</Box>
			</Modal>
		</>
	);
};

export default ChangePasswordModal;
