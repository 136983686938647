import AccountCircle from '@mui/icons-material/AccountCircle';
import LanguageIcon from '@mui/icons-material/Language';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { fetchEmployerSelfProfile } from '../../apis/employerAPI';
import Logo from '../../assets/svgs/Logo';
import ChangePasswordModal from '../../components/molecules/ChangePasswordModal';
import CustomMenuItemDropDown from '../../components/molecules/CustomMenuItemDropDown';
import DashboardBaseInput from '../../components/molecules/DashboardBaseInput';
import LogoutModal from '../../components/molecules/LogoutModal';
import NotificationsDropDown from '../../components/organisms/NotificationsDropDown';
import useAuth from '../../hooks/useAuth';
import { removeAuthToken } from '../../reducers/authReducer';
import RightDrawer from '../sidebar/RightDrawer';

const Header = () => {
	const { t } = useTranslation();
	const [profileAnchorEl, profileSetAnchorEl] = useState(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
	const queryLists = {};
	const { dispatch } = useAuth();

	const { data: selfProfileData } = useQuery('header/profile', fetchEmployerSelfProfile);

	const isMenuOpen = Boolean(profileAnchorEl);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const handleProfileMenuOpen = (event) => {
		profileSetAnchorEl(event.currentTarget);
	};

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMenuClose = () => {
		profileSetAnchorEl(null);
		handleMobileMenuClose();
	};

	const handleLogout = () => {
		handleMenuClose();
		dispatch(removeAuthToken());
		window.location.reload();
	};

	const menuId = 'primary-search-account-menu';
	const renderMenu = (
		<Menu
			anchorEl={profileAnchorEl}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			id={menuId}
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			<ChangePasswordModal />
			{/* <Link to="/company-profile" style={{ textDecoration: 'none', color: '#24282D' }}>
				<CustomMenuItem
					sx={{ display: 'flex', gap: '10px', fontWeight: '500', alignItems: 'center' }}
					iconSx={{ color: '#00807F' }}
					icon={
						<LockOutlinedIcon
							style={{
								marginTop: '5px',
							}}
						/>
					}
					handleMenuClose={handleMenuClose}
					name="Profile"
				/>
			</Link> */}

			<CustomMenuItemDropDown
				sx={{
					display: 'flex',
					gap: '10px',
					fontFamily: 'Outfit',
					justifyContent: 'center',
					alignItems: 'center',
				}}
				iconSx={{ display: 'flex', color: '#24282D', gap: '10px', fontWeight: '500' }}
				// iconSx={{ color: '#00807F' }}
				icon={<LanguageIcon />}
				// handleMenuClose={handleMenuClose}
				name="Language"
			/>

			<LogoutModal handleLogout={handleLogout} />
		</Menu>
	);

	const mobileMenuId = 'primary-search-account-menu-mobile';
	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			<MenuItem onClick={handleProfileMenuOpen}>
				<IconButton
					size="large"
					aria-label="account of current user"
					aria-controls="primary-search-account-menu"
					aria-haspopup="true"
					color="inherit"
				>
					<AccountCircle />
				</IconButton>
				<p>{t('Profile')} </p>
			</MenuItem>
			<MenuItem>
				<IconButton size="large" aria-label="show 17 new notifications" color="#8E9AA6">
					<NotificationsIcon />
				</IconButton>
				<p>{t('Notifications')} </p>
			</MenuItem>
		</Menu>
	);

	return (
		<>
			<AppBar
				position="absolute"
				sx={{
					backgroundColor: 'white',
					boxShadow: 'none!important',
					height: '80px',
					padding: '10px',
				}}
			>
				<Toolbar>
					<Grid container spacing={2} sx={{ paddingTop: '12px' }}>
						<Grid item xs={2} md={2} sx={{ display: 'flex', alignItems: 'center' }}>
							<Box
								sx={{
									width: { xl: '57%', md: '90%', sm: '34%' },
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<Link to="/dashboard" style={{ textDecoration: 'none' }}>
									<Logo />
								</Link>
							</Box>
						</Grid>
						{/* input  */}
						<Grid
							item
							xs={7}
							md={7}
							sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
						>
							<Box
								sx={{
									flexGrow: 1,
									'@media (max-width: 1350px)': {
										flexGrow: 1,
										width: '400px',
									},
									'@media (max-width: 768px)': {
										flexGrow: 1,
										width: '300px',
									},
									// if needed sm is to screen to input show place here
									display: { xs: 'none', md: 'block', lg: 'block' },
								}}
							>
								<DashboardBaseInput
									queryLists={queryLists}
									page="job-search"
									placeholder="Search title or keyword"
								/>
							</Box>
						</Grid>

						<Grid
							item
							xs={3}
							md={3}
							sx={{
								justifyContent: 'end',
								display: { md: 'flex', xs: 'flex' },
								// alignItems: 'center',
							}}
						>
							<Box
								sx={{
									display: 'flex',
									// marginTop: '25px',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<NotificationsDropDown uuid={selfProfileData?.uuid} />

								<Box
									sx={{
										height: '30px',
										width: '1px',
										background: '#D5D5D5',
										'@media (min-width: 768px)': {
											marginLeft: '10px',
										},
									}}
								/>

								{/* --------- Profile name --------- */}
								<Typography
									variant="h5"
									sx={{
										display: 'none',
										color: '#24282D',
										fontSize: '16px',
										fontWeight: 600,
										marginRight: '15px',
										fontFamily: 'Outfit',
										marginLeft: '20px',
										'@media (min-width: 768px)': {
											display: 'block',
										},
									}}
								>
									{selfProfileData?.company_name}
								</Typography>
							</Box>

							<IconButton
								size="large"
								sx={{ fontSize: '2rem' }}
								edge="end"
								aria-label="account of current user"
								aria-controls={menuId}
								aria-haspopup="true"
								onClick={handleProfileMenuOpen}
								color="#8E9AA6"
							>
								{selfProfileData?.avatar ? (
									<Box
										sx={{
											borderRadius: '100px',
											overflow: 'hidden',
											width: '50px',
											height: '50px',
										}}
									>
										<img
											src={selfProfileData?.avatar}
											alt={selfProfileData?.company_name}
											height={50}
											width={50}
											style={{
												objectFit: 'cover',
											}}
										/>
									</Box>
								) : (
									<AccountCircle sx={{ color: '#00807F' }} />
								)}
							</IconButton>

							<RightDrawer />
							{/* </Box> */}
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			{renderMobileMenu}
			{renderMenu}
		</>
	);
};

export default Header;
