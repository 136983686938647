/* eslint-disable react/jsx-props-no-spreading */
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, IconButton, Input, InputAdornment } from '@mui/material';
import { useField } from 'formik';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { errorMsgStyle } from '../../utils/styles';

const PasswordInput = ({ sx, placeholder, name }) => {
	const { t } = useTranslation();
	const [showPassword, setShowPassword] = useState(false);
	const [field, meta] = useField(name);

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	return (
		<>
			<Input
				name={name}
				id={`outlined-basic-${name}`}
				type={showPassword ? 'text' : 'password'}
				variant="outlined"
				disableUnderline
				placeholder={placeholder}
				autofill="off"
				sx={sx}
				autoComplete="off"
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							sx={{ color: 'black' }}
							aria-label={t('toggle password visibility')}
							onClick={handleClickShowPassword}
							onMouseDown={handleMouseDownPassword}
							edge="end"
						>
							{showPassword ? <VisibilityOff /> : <Visibility />}
						</IconButton>
					</InputAdornment>
				}
				{...field}
			/>
			{meta.touched && meta.error ? (
				<Box
					sx={{
						...errorMsgStyle,
						marginBottom: '-15px',
						'@media (max-width: 1024px)': {
							marginBottom: '-5px',
						},
					}}
				>
					{t(meta.error)}
				</Box>
			) : null}
		</>
	);
};

export default PasswordInput;
