import i18next from 'i18next';
import toast from 'react-hot-toast';
import api from './config/axiosInstance';

const employerAPI = {
	async updateEmployerSelfProfile(data) {
		const response = await api.patch('/employer/update/', data);
		const result = response.data;

		if (![200, 201].includes(response.status)) {
			toast.error(i18next.t('Internal server error, Please try again later.'));
		}
		// <Navigate to="/company-profile" />;
		return result;
	},

	async fetchEmployerSelfProfile() {
		const response = await api.get('/employer/self');
		const result = response.data;

		if (![200, 201].includes(response.status)) {
			toast.error(i18next.t('Internal server error, Please try again later.'));
		}

		return result;
	},
	async fetchCompanyDetails() {
		try {
			const response = await api.get('/employer/self/');
			const result = response.data;
			return result;
		} catch (error) {
			return error;
		}
	},
};

export const { updateEmployerSelfProfile, fetchEmployerSelfProfile, fetchCompanyDetails } =
	employerAPI;

export default employerAPI;
