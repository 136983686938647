/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
// import { USER_MODE_SELLER } from '../config/constants';
// import useAuth from '../hooks/useAuth';
// import useCurrentUserRole from '../hooks/useCurrentUserRole';

const AuthenticatedAndNotProtectedRoute = ({ children = null, redirectTo = '/login' }) => {
	const { state: isAuthenticated } = useAuth();
	return isAuthenticated ? children : <Navigate to={redirectTo} />;
};

const UnAuthenticated = ({ children = null, redirectTo = '/login' }) => {
	const { state: isAuthenticated } = useAuth();
	return !isAuthenticated ? children : <Navigate to={redirectTo} />;
};

const PublicButNotHaveAccess = ({ children = null }) => {
	// const { state: currentUserRole } = useCurrentUserRole();
	const currentUserRole = ''; // test version

	const location = useLocation();

	return currentUserRole === 'USER_MODE_SELLER' && location.pathname === '/' ? (
		<Navigate to="/dashboard" />
	) : (
		children
	);
};

const routeConfig = (routes) => {
	if (!routes) return <div />;

	window.scrollTo(0, 0);

	return (
		<Routes>
			{routes?.map((route) => (
				<Route
					key={route.name}
					path={route.path}
					element={
						route?.public ? (
							<PublicButNotHaveAccess>
								<route.component />
							</PublicButNotHaveAccess>
						) : route.protected ? (
							<AuthenticatedAndNotProtectedRoute>
								<route.component />
							</AuthenticatedAndNotProtectedRoute>
						) : (
							<UnAuthenticated>
								<route.component />
							</UnAuthenticated>
						)
					}
				/>
			))}
		</Routes>
	);
};

export default routeConfig;
