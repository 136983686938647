const TopGradientCircleBg = () => {
	return (
		<svg
			width={348}
			height={312}
			viewBox="0 0 348 312"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				opacity="0.05"
				x={140}
				y="392.051"
				width={440}
				height={440}
				rx={220}
				transform="rotate(-120 140 392.051)"
				fill="url(#paint0_linear_82_3114)"
			/>
			<rect
				opacity="0.05"
				x="167.818"
				y="288.234"
				width={288}
				height={288}
				rx={144}
				transform="rotate(-120 167.818 288.234)"
				fill="url(#paint1_linear_82_3114)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_82_3114"
					x1={360}
					y1="392.051"
					x2={360}
					y2="638.247"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#214E92" />
					<stop offset={1} stopColor="#214E92" stopOpacity={0} />
				</linearGradient>
				<linearGradient
					id="paint1_linear_82_3114"
					x1="311.818"
					y1="288.234"
					x2="311.818"
					y2="449.381"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#214E92" />
					<stop offset={1} stopColor="#214E92" stopOpacity={0} />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default TopGradientCircleBg;
