/* eslint-disable prettier/prettier */
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MuiInput from '@mui/material/Input';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { fetchSalaryRange } from '../../apis/salaryRange';

const Input = styled(MuiInput)`
	width: 42px;
`;

function convertToRange(value, srcRange, dstRange) {
	if (value < srcRange[0] || value > srcRange[1]) {
		return NaN;
	}

	const srcMax = srcRange[1] - srcRange[0];
	const dstMax = dstRange[1] - dstRange[0];
	const adjValue = value - srcRange[0];

	return (adjValue * dstMax) / srcMax + dstRange[0];
}

const RangeSliderCustom = ({ minSalary, value, setValue, maxSalary, setSalaryRangeInput }) => {
	const { data: salaryRangeData } = useQuery('salary-range', fetchSalaryRange);
	const [salaryRangeMaxMin, setSalaryRangeMaxMin] = useState({ lower: 0, upper: 10000 });

	useEffect(() => {
		if (!salaryRangeData || salaryRangeData?.length === 0) {
			console.error('No salary range available');
			return;
		}

		const lowest = Math.min(...salaryRangeData.map((item) => item.range.lower));
		const highest = Math.max(...salaryRangeData.map((item) => item.range.upper));

		setSalaryRangeMaxMin({
			lower: lowest,
			upper: highest,
		});
	}, [salaryRangeData]);

	useEffect(() => {
		setSalaryRangeInput([
			salaryRangeMaxMin?.lower,
			salaryRangeMaxMin?.upper,
		]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [salaryRangeMaxMin]);

	const handleSliderChange = (event, newValue) => {
		const low = convertToRange(
			newValue[0],
			[0, 100],
			[salaryRangeMaxMin?.lower, salaryRangeMaxMin?.upper],
		);
		const high = convertToRange(
			newValue[1],
			[0, 100],
			[salaryRangeMaxMin?.lower, salaryRangeMaxMin?.upper],
		);

		setValue(newValue);
		setSalaryRangeInput([low, high]);
	};

	const handleInputChange = (event) => {
		setValue(event.target.value === '' ? 0 : Number(event.target.value));
	};

	const handleBlur = () => {
		if (value < 0) {
			setValue(0);
		} else if (value > 200) {
			setValue(200);
		}
	};

	const inputSx = {
		border: '1px solid #EFF1F3',
		padding: '8px 16px',
		fontSize: '14px',
		textTransform: 'uppercase',
		color: '#8E9AA6',
		width: '128px',
		borderRadius: '44px',
		fontWeight: '600',
		fontFamily: 'Outfit',
	};

	return (
		<Box sx={{ width: '100%' }} className="search-filter">
			<Grid item xs>
				<Slider
					color="secondary"
					value={value}
					onChange={handleSliderChange}
					aria-labelledby="input-slider"
				/>
			</Grid>

			<Grid container spacing={2} alignItems="center" justifyContent="space-between">
				<Grid item>
					<Input
						sx={inputSx}
						name={minSalary}
						disabled
						disableUnderline
						value={`SR ${convertToRange(
							value[0],
							[0, 100],
							[salaryRangeMaxMin?.lower, salaryRangeMaxMin?.upper],
						)}`}
						size="small"
						onChange={handleInputChange}
						onBlur={handleBlur}
						// inputProps={{
						// 	step: 10,
						// 	min: 0,
						// 	max: 50000,
						// 	type: 'text',
						// 	'aria-labelledby': 'input-slider',
						// }}
					/>
				</Grid>

				<Grid item>
					<Input
						sx={inputSx}
						disabled
						value={`SR ${convertToRange(
							value[1],
							[0, 100],
							[salaryRangeMaxMin?.lower, salaryRangeMaxMin?.upper],
						)}`}
						size="small"
						name={maxSalary}
						disableUnderline
						onChange={handleInputChange}
						onBlur={handleBlur}
						inputProps={{
							// step: 10,
							// min: 0,
							// max: 50000,
							type: 'text',
							'aria-labelledby': 'input-slider',
						}}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default RangeSliderCustom;
