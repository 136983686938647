import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Menu, MenuItem } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckmarkGreen from '../../assets/svgs/CheckmarkGreen';
import useLanguage from '../../hooks/useLanguage';
import { changeLanguage } from '../../reducers/languageReducer';

const languages = [
	{
		code: 'en',
		name: 'English',
		dir: 'ltr',
		country_code: 'gb',
	},
	{
		code: 'ar',
		name: 'العربية',
		dir: 'rtl',
		country_code: 'sa',
	},
];

const CustomMenuItemDropDown = ({ icon, iconSx, name, sx }) => {
	const { i18n } = useTranslation();
	const didMountRef = useRef(false);
	const menuName = name;
	const [anchorEl, setAnchorEl] = useState(null);
	const [languageName, setLanguageName] = useState(languages[0]);
	const open = Boolean(anchorEl);
	const { dispatch } = useLanguage();

	// for first render
	useEffect(() => {
		const lang = localStorage.getItem('language');
		const langObj = languages?.filter((item) => item.code === lang)[0];
		setLanguageName(langObj);
	}, []);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (lang) => {
		setAnchorEl(null);
		if (typeof lang?.code === 'string') {
			if (lang?.code === languageName?.code) return;
			setLanguageName(lang);
		}
	};

	useEffect(() => {
		if (didMountRef.current) {
			if (i18n.language) {
				dispatch(changeLanguage(languageName?.code));
				localStorage.setItem('language', languageName?.code);
				i18n?.changeLanguage(languageName?.code);
				if (languageName?.code === 'ar') {
					document.dir = 'rtl';
				} else {
					document.dir = 'ltr';
				}
			}
		}
		didMountRef.current = true;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [languageName]);

	return (
		<>
			<MenuItem sx={sx} onClick={handleClick}>
				<Box sx={iconSx}>
					<Box
						component="span"
						sx={{
							color: '#00807F',
						}}
					>
						{icon}
					</Box>
					{menuName}
				</Box>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						gap: '5px',
						color: '#8E9AA6',
						fontSize: '12px',
						fontFamily: 'Outfit',
						marginBottom: '5px',
					}}
				>
					<p>{languageName?.name}</p>
					<ArrowForwardIosIcon
						sx={{
							color: '#8E9AA6',
							fontSize: '12px',
							rotate: languageName?.code === 'en' ? '0deg' : '180deg',
						}}
					/>
				</Box>
			</MenuItem>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button', // Pass the aria-labelledby attribute here
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
			>
				{/* Add your menu items here */}
				{languages?.map((item) => (
					<MenuItem
						key={item.code}
						onClick={() => handleClose(item)}
						sx={{
							fontFamily: 'Outfit',
							paddingLeft: '35px',
							position: 'relative',
							color: item.code === languageName?.code ? '#00807F' : '#8E9AA6',
						}}
					>
						{item.code === languageName?.code && (
							<Box
								component="span"
								sx={{
									position: 'absolute',
									fontFamily: 'Outfit',
									top: '10px',
									left: '10px',
								}}
							>
								<CheckmarkGreen />
							</Box>
						)}
						{item?.name}
					</MenuItem>
				))}
				{/* Add more menu items as needed */}
			</Menu>
		</>
	);
};

export default CustomMenuItemDropDown;
