import MenuIcon from '@mui/icons-material/Menu';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { forwardRef, useState } from 'react';
import { ErrorIcon } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { Link, NavLink as NavLinkBase } from 'react-router-dom';
import BookmarkIcon from '../../assets/svgs/BookmarkIcon';
import DashboardIcon from '../../assets/svgs/DashboardIcon';
import Logo from '../../assets/svgs/Logo';
import WorkIcon from '../../assets/svgs/WorkIcon';
import DashboardBaseInput from '../../components/molecules/DashboardBaseInput';
import { useWindowSize } from '../../hooks/useWindowSize';

const classes = {
	activeLink: 'active-link',
	link: 'link',
};

// eslint-disable-next-line react/display-name
const NavLink = forwardRef((props, ref) => {
	return (
		<NavLinkBase
			ref={ref}
			className={(navData) =>
				`${navData.isActive ? classes.activeLink : classes.link} ${props.className}`
			}
			to={props.to}
		>
			{props.children}
		</NavLinkBase>
	);
});

const RightDrawer = () => {
	const [isRightOpen, setIsRightOpen] = useState(false);
	const { width } = useWindowSize();

	const toggleDrawer = () => {
		setIsRightOpen(!isRightOpen);
	};
	const { t } = useTranslation();

	const list = (
		<Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer}>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'flex-start',
					padding: '10% 8%',
					alignItems: 'center',
				}}
			>
				<Link to="/dashboard">
					<Logo />
				</Link>
			</Box>
			<Box>
				<DashboardBaseInput placeholder={t('Search title or keyword')} />
			</Box>
			<List sx={{ marginTop: '1rem' }}>
				<ListItem sx={{ color: 'black' }} component={NavLink} to="/dashboard">
					{' '}
					<ListItemIcon sx={{ minWidth: '0px' }}>
						<DashboardIcon sx={{ color: '#00807F' }} />
					</ListItemIcon>
					<ListItemText sx={{ marginLeft: '1rem' }} primary={t('Dashboard')} />
				</ListItem>
				<ListItem sx={{ color: 'black' }} component={NavLink} to="/job-posts">
					<ListItemIcon sx={{ minWidth: '0px' }}>
						<WorkIcon sx={{ color: '#00807F' }} />
					</ListItemIcon>
					<ListItemText sx={{ marginLeft: '1rem' }} primary={t('Job Posts')} />
				</ListItem>
				<ListItem sx={{ color: 'black' }} component={NavLink} to="company-profile">
					<ListItemIcon sx={{ minWidth: '0px' }}>
						<WorkIcon sx={{ color: '#00807F' }} />
					</ListItemIcon>
					<ListItemText sx={{ marginLeft: '1rem' }} primary={t('Company Profile')} />
				</ListItem>
				<ListItem to="/favorites" sx={{ color: 'black' }} component={NavLink}>
					<ListItemIcon sx={{ minWidth: '0px' }}>
						<BookmarkIcon sx={{ color: '#00807F' }} />
					</ListItemIcon>
					<ListItemText sx={{ marginLeft: '1rem' }} primary={t('Favorites')} />
				</ListItem>
				<ListItem to="/report-issue" sx={{ color: 'black' }} component={NavLink}>
					<ListItemIcon sx={{ minWidth: '0px' }}>
						<ErrorIcon sx={{ color: '#00807F' }} />
					</ListItemIcon>
					<ListItemText sx={{ marginLeft: '1rem' }} primary={t('Report An Issue')} />
				</ListItem>
			</List>
		</Box>
	);

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{width <= 899 ? (
				<>
					<Button
						onClick={toggleDrawer}
						sx={{ fontSize: '2rem', display: { sm: 'block', md: 'none' } }}
					>
						<MenuIcon sx={{ color: '#8E9AA6' }} />
					</Button>

					<Drawer anchor="right" open={isRightOpen} onClose={toggleDrawer}>
						{list}
					</Drawer>
				</>
			) : null}
		</>
	);
};

export default RightDrawer;
