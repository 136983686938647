import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { Badge, Button } from '@mui/material';
import { useEffect, useState } from 'react';

import { useMutation, useQuery } from 'react-query';
import { useWebSocket } from '../../apis/webSocketNotification';
// eslint-disable-next-line prettier/prettier
import {
	fetchHasNotifications,
	fetchNotifications,
	seenNotification,
} from '../../apis/notificationsApis';
import CustomDropDown from '../atoms/CustomDropDown';
import NotificationsCard from '../molecules/NotificationsCard';

// eslint-disable-next-line no-unused-vars
const NotificationsDropDown = ({ uuid }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [notifications, setNotifications] = useState([]);

	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const buttonId = 'notifications-button';
	const menuId = 'notifications-menu';

	const {
		data: notificationList,
		isLoading: isLoadingNotificationIsLoading,
		refetch: refetchNotificationList,
	} = useQuery(['notification-list'], () => fetchNotifications());

	const { data: hasNotifications, refetch: refetchHasNotification } = useQuery(
		['has-notification'],
		() => fetchHasNotifications(),
	);

	const { mutateAsync: mutateAsyncNotificationSeen } = useMutation(['notification-seen'], (id) =>
		seenNotification(id),
	);

	useEffect(() => {
		setNotifications(notificationList?.results);
	}, [notificationList]);

	const [message] = useWebSocket();

	useEffect(() => {
		if (!message) return;

		setNotifications((prevNotifications) => [{ message }, ...prevNotifications]);
		refetchHasNotification();
	}, [message, refetchHasNotification]);

	const handleNotificationSeen = async (id) => {
		handleClose();
		await mutateAsyncNotificationSeen(id);
		refetchNotificationList();
		refetchHasNotification();
	};

	return (
		<>
			<Button
				onClick={handleClick}
				sx={{
					position: 'relative',
					borderRadius: '100px',
					cursor: 'pointer',
				}}
			>
				<NotificationsNoneOutlinedIcon sx={{ color: '#00807F', fontSize: '33px' }} />
				{hasNotifications?.data ? (
					<Badge
						type="button"
						id={buttonId}
						aria-controls={open ? menuId : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						sx={{
							position: 'absolute',
							top: '5px',
							right: document.dir === 'rtl' ? 'unset' : '16px',
						}}
						badgeContent=""
						color="error"
					/>
				) : null}
			</Button>

			<CustomDropDown
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				menuId={menuId}
				buttonId={buttonId}
			>
				<NotificationsCard
					notifications={notifications}
					handleNotificationSeen={handleNotificationSeen}
					isLoadingNotificationIsLoading={isLoadingNotificationIsLoading}
				/>
			</CustomDropDown>
		</>
	);
};

export default NotificationsDropDown;
