import { lazy } from 'react';
import LoginPage from '../pages/LoginPage/LoginPage';

const PaymentCallbackPage = lazy(() => import('../pages/PaymentCallbackPage/PaymentCallbackPage'));
const JobSearchResultPage = lazy(() => import('../pages/JobSearchResult/JobSearchResultPage'));
const EditJobPage = lazy(() => import('../pages/EditJobPage/EditJobPage'));
const AddInfoPage = lazy(() => import('../pages/AddInfoPage/AddInfoPage'));
const AddJobPage = lazy(() => import('../pages/AddNewJobPage/AddJobPage'));
const CompanyDetails = lazy(() => import('../pages/CompanyDetailsPage/CompanyDetails'));
const CreateNewPassword = lazy(() => import('../pages/CreateNewPasswordPage/CreateNewPassword'));
const DashboardPage = lazy(() => import('../pages/DashboardPage/DashboardPage'));
const EditCompanyInfo = lazy(() => import('../pages/EditCompanyInfo/EditCompanyInfo'));
const FavouriteCandidatePage = lazy(() =>
	import('../pages/FavouriteCandidatePage/FavouriteCandidatePage'),
);
const TermsAndRegulationPage = lazy(() =>
	import('../pages/TermsAndRegulationPage/TermsAndRegulationPage'),
);
const CandidateProfilePage = lazy(() => import('../pages/JobPostsPage/CandidateProfilePage'));
const JobPostsPage = lazy(() => import('../pages/JobPostsPage/JobPostsPage'));
const SelectedJobPage = lazy(() => import('../pages/JobPostsPage/SelectedJobPage'));
const EnterOtpPage = lazy(() => import('../pages/OTPPage/EnterOtpPage'));
const ReportIssuePage = lazy(() => import('../pages/ReportIssuePage/ReportIssuePage'));
const GetOtpPage = lazy(() => import('../pages/ResetPasswordPage/GetOtpPage'));
const OTPVerifyPage = lazy(() => import('../pages/OTPVerifyPage/OTPVerifyPage'));

const publiclyAccessibleRoutes = [
	{
		id: '53db4dfc-8e47-49d5-86b1-ff6ef0a17e78',
		name: 'HomePage',
		component: LoginPage,
		path: '/',
		exact: true,
		protected: false,
	},
	{
		id: '53db4dfc-8e47-49d5-86b1-ff6ef0a17e78',
		name: 'LogInPage',
		component: LoginPage,
		path: '/login',
		exact: true,
		protected: false,
	},
	{
		id: '1b4f62ce-aac5-4bba-8fd6-32f0960c992f',
		name: 'SignUpPage',
		component: AddInfoPage,
		path: '/signup',
		exact: true,
		protected: false,
	},
	{
		id: 'aacb0997-1755-48e6-937e-036c6a95e23b',
		name: 'GetOtpPage',
		component: GetOtpPage,
		path: '/reset-password',
		exact: true,
		protected: false,
	},
	{
		id: 'b8f5ffa3-a963-4d7a-b7d5-d9628c068026',
		name: 'EnterOtpPage',
		component: EnterOtpPage,
		path: '/enter-otp',
		exact: true,
		protected: false,
	},
	{
		id: '39e39451-0660-4f36-9b5f-2846c3f31a82',
		name: 'OTPVerifyPage',
		component: OTPVerifyPage,
		path: '/verify-account/:userId',
		exact: true,
		protected: false,
	},
	{
		id: '17ee16eb-5080-41fb-a2c8-f06e322e1818',
		name: 'CreateNewPassword',
		component: CreateNewPassword,
		path: '/new-password',
		exact: true,
		protected: false,
	},
	{
		id: '101a410d-2618-4213-9f61-e499367d85ed',
		name: 'TermsAndRegulation',
		component: TermsAndRegulationPage,
		path: '/terms',
		exact: true,
		protected: false,
	},
];

export const authenticatedRoutes = [
	{
		id: 'cc9232c1-3cd4-4013-b159-315342c1e91e',
		name: 'dashboard',
		component: DashboardPage,
		path: '/dashboard',
		exact: true,
		protected: true,
	},
	{
		id: '3f72cc7e-55e5-11ee-8c99-0242ac120002',
		name: 'AddJob',
		component: AddJobPage,
		path: '/add-job',
		exact: true,
		protected: true,
	},
	{
		id: '775038gf-a96d-4857-bf81-39cea5017652',
		name: 'EditJob',
		component: EditJobPage,
		path: '/edit-job/:id',
		exact: true,
		protected: true,
	},
	{
		id: '9f040ebc-b143-4c79-981d-786403eab788',
		name: 'jobPosts',
		component: JobPostsPage,
		path: '/job-posts',
		exact: true,
		protected: true,
	},
	{
		id: '7751009f-a96d-4857-bf81-39cea5017652',
		name: 'selectedJob',
		component: SelectedJobPage,
		path: '/job-posts/:uuid',
		exact: true,
		protected: true,
	},

	{
		id: '8bb431c6-e0ab-4a3b-9908-1f81ba061ade',
		name: 'CandidateProfile',
		component: CandidateProfilePage,
		path: '/job-posts/candidate/profile/:uuid',
		exact: true,
		protected: true,
	},
	{
		id: '8bb431c6-e0ab-4a3b-9908-1f81ba061adr',
		name: 'CompanyDetails',
		component: CompanyDetails,
		path: '/company-profile',
		exact: true,
		protected: true,
	},
	{
		id: '8bb431c6-e0ab-4a3b-9908-1f81ba061bcr',
		name: 'EditCompanyInfo',
		component: EditCompanyInfo,
		path: '/edit-company-profile',
		exact: true,
		protected: true,
	},
	{
		id: '9137c5e8-b4a2-4dbc-ab42-d688c8d1f795',
		name: 'FavouriteCandidate',
		component: FavouriteCandidatePage,
		path: '/favorites',
		exact: true,
		protected: true,
	},
	{
		id: 'f19a9d76-2bca-4f5b-bc20-794ad34ce43b',
		name: 'ReportAnIssue',
		component: ReportIssuePage,
		path: '/report-issue',
		exact: true,
		protected: true,
	},
	{
		id: '516f9e08-5b0d-4bfd-9897-80e8ba592b16',
		name: 'JobSearch',
		component: JobSearchResultPage,
		path: '/job-search',
		exact: true,
		protected: true,
	},
	{
		id: '17ee16eb-516f9e08-41fb-a2c8-f06e322e1818',
		name: 'PaymentCallbackPage',
		component: PaymentCallbackPage,
		path: '/payment-callback',
		exact: true,
		protected: true,
	},
];

const routes = [...publiclyAccessibleRoutes, ...authenticatedRoutes];

export default routes;
