export const baseURL = process.env.REACT_APP_API_SERVER || 'https://api.dev.opus.sa/';
export const websocketServer = process.env.REACT_APP_WEBSOCKET_SERVER || 'ws://api.dev.opus.sa/';
export const tinyMCEApiKey = process.env.REACT_APP_TINYMCE_API_KEY || '';
export const geolocationApiKey = process.env.REACT_APP_GEOLOCATION_API_KEY || '';
export const appleStoreUrl =
	process.env.REACT_APP_APPLE_STORE_URL ||
	'https://play.google.com/store/apps/details?id=com.ewn.opus';
export const googlePlayUrl =
	process.env.REACT_APP_GOOGLE_PLAY_URL ||
	'https://apps.apple.com/pl/app/opus-employer/id6470737573';
export const sentryDNS = process.env.REACT_APP_SENTRY_DNS || '';
export const southArabicCountryId = process.env.REACT_APP_SOUTH_ARABIC_COUNTRY_ID || 1;
export const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY || '';
