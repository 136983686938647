const WorkIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
			<mask
				id="mask0_3873_6493"
				style={{ maskType: 'alpha' }}
				maskUnits="userSpaceOnUse"
				x={0}
				y={0}
				width={24}
				height={24}
			>
				<rect width={24} height={24} fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_3873_6493)">
				<path
					d="M4.3 20.5C3.8 20.5 3.375 20.325 3.025 19.975C2.675 19.625 2.5 19.2 2.5 18.7V8.3C2.5 7.8 2.675 7.375 3.025 7.025C3.375 6.675 3.8 6.5 4.3 6.5H8.5V4.8C8.5 4.3 8.675 3.875 9.025 3.525C9.375 3.175 9.8 3 10.3 3H13.7C14.2 3 14.625 3.175 14.975 3.525C15.325 3.875 15.5 4.3 15.5 4.8V6.5H19.7C20.2 6.5 20.625 6.675 20.975 7.025C21.325 7.375 21.5 7.8 21.5 8.3V18.7C21.5 19.2 21.325 19.625 20.975 19.975C20.625 20.325 20.2 20.5 19.7 20.5H4.3ZM4.3 19H19.7C19.7667 19 19.8333 18.9667 19.9 18.9C19.9667 18.8333 20 18.7667 20 18.7V8.3C20 8.23333 19.9667 8.16667 19.9 8.1C19.8333 8.03333 19.7667 8 19.7 8H4.3C4.23333 8 4.16667 8.03333 4.1 8.1C4.03333 8.16667 4 8.23333 4 8.3V18.7C4 18.7667 4.03333 18.8333 4.1 18.9C4.16667 18.9667 4.23333 19 4.3 19ZM10 6.5H14V4.8C14 4.73333 13.9667 4.66667 13.9 4.6C13.8333 4.53333 13.7667 4.5 13.7 4.5H10.3C10.2333 4.5 10.1667 4.53333 10.1 4.6C10.0333 4.66667 10 4.73333 10 4.8V6.5Z"
					fill="#00807F"
				/>
			</g>
		</svg>
	);
};

export default WorkIcon;
