import toast from 'react-hot-toast';
import api from './config/axiosInstance';
import { i18n } from '../i18n/config';

const CandidateApis = {
	async getAllCandidates(id = '', queryString = '', params = []) {
		const [, allCandidatespage, limitAllCandidates] = params;
		const query = new URLSearchParams();
		query.set('size', limitAllCandidates);
		query.set('page', allCandidatespage);
		try {
			const response = await api.get(`/job_application/applicants/${id}/?${queryString}&${query}`);
			const result = response?.data?.results;
			return result;
		} catch (error) {
			return error;
		}
	},
	async getAllCandidatesApplications(id = '', queryString = '', params = []) {
		const [, allCandidatespage, limitAllCandidates] = params;
		const query = new URLSearchParams();
		query.set('size', limitAllCandidates);
		query.set('page', allCandidatespage);
		try {
			const response = await api.get(
				`/job_application/applicants_web/${id}/?${queryString}&${query}`,
			);
			const result = response?.data?.results;
			return result;
		} catch (error) {
			return error;
		}
	},
	async getProfileInfo(id = '') {
		try {
			const response = await api.get(`/candidate/${id}/`);
			const result = response?.data;

			return result;
		} catch (error) {
			return error;
		}
	},
	async addFavoriteCandidate(id = '') {
		try {
			const response = await api.post(`employer_favorite_candidate/`, id);
			const result = response?.data;
			if ([200, 201].includes(response?.status)) {
				toast.success(result?.message);
			}
			return result;
		} catch (error) {
			toast.error(error?.response?.data?.errors[0]?.message);

			return error;
		}
	},
	async removeCandidateFromFavoriteList(id = '') {
		try {
			const response = await api.delete(`employer_favorite_candidate/${id}/`);
			const result = response?.data;
			if ([200, 204].includes(response?.status)) {
				toast.success(i18n.t('Candidate remove successfully'));
			}

			return result;
		} catch (error) {
			toast.error(error?.response?.data?.message);
			return error;
		}
	},
	async addReview(data = {}) {
		try {
			const response = await api.post(`/candidate_review/`, data);
			const result = response?.data;

			if ([200, 201].includes(response?.status)) {
				toast.success(result?.message);
			}
			return result;
		} catch (error) {
			toast.error(error?.response?.data?.errors[0]?.message);

			return error;
		}
	},
	async candidateSelected(id = '') {
		try {
			const response = await api.post(`/job_application/applicants/${id}/selected/`);
			const result = response?.data;
			if ([200, 201].includes(response?.status)) {
				toast.success(result?.message);
			}
			return result;
		} catch (error) {
			toast.error(error?.response?.data?.errors[0]?.message);

			return error;
		}
	},
	async candidateRejected(id = '') {
		try {
			const response = await api.post(`/job_application/applicants/${id}/rejected/`);
			const result = response?.data;
			if ([200, 201].includes(response?.status)) {
				toast.success(result?.message);
			}
			return result;
		} catch (error) {
			toast.error(error?.response?.data?.errors[0]?.message);

			return error;
		}
	},
	async getFavoriteList() {
		try {
			const response = await api.get(`/employer_favorite_candidate/`);
			const result = response?.data?.results;
			return result;
		} catch (error) {
			return error;
		}
	},

	async favoriteCandidateList(queryList = '', params = []) {
		const [, page, limit] = params;
		const query = new URLSearchParams();
		query.set('size', limit);
		query.set('page', page);

		try {
			const response = await api.get(`/employer_favorite_candidate/?${queryList}&${query}`);
			const result = response?.data?.results;
			return result;
		} catch (error) {
			return error;
		}
	},
	async favoriteCandidateListWithParams(queryList = '', params = []) {
		const [, page, limit] = params;
		const query = new URLSearchParams();
		query.set('size', limit);
		query.set('page', page);

		try {
			const response = await api.get(`/web_employer_favorite_candidate/?${queryList}&${query}`);
			const result = response?.data?.results;
			return result;
		} catch (error) {
			return error;
		}
	},

	async getAllSelectedCandidate(id = '', queryString = '', params = []) {
		const [, selectedCandidatesPage, limitSelectedCandidates] = params;
		const query = new URLSearchParams();
		query.set('size', limitSelectedCandidates);
		query.set('page', selectedCandidatesPage);
		try {
			const response = await api.get(
				`job_application/applicants/${id}/?is_selected=true&${queryString}&${query}`,
			);
			const result = response?.data?.results;
			return result;
		} catch (error) {
			return error;
		}
	},

	async getExperiences() {
		try {
			const response = await api.get(`experience_level/`);
			return response.data;
		} catch (error) {
			return error;
		}
	},

	async getAllPromoteCandidates() {
		try {
			const response = await api.get(`candidate_ramdom/`);

			return response?.data;
		} catch (error) {
			return error;
		}
	},
	async getSkillLists() {
		try {
			const response = await api.get(`/skill/`);
			const result = response?.data;
			return result;
		} catch (error) {
			return error;
		}
	},
};
export const {
	getAllCandidates,
	getProfileInfo,
	addFavoriteCandidate,
	addReview,
	candidateSelected,
	removeCandidateFromFavoriteList,
	candidateRejected,
	favoriteCandidateList,
	favoriteCandidateListWithParams,
	getAllSelectedCandidate,
	getExperiences,
	getFavoriteList,
	getSkillLists,
	getAllPromoteCandidates,
	getAllCandidatesApplications,
} = CandidateApis;
export default CandidateApis;
