const ErrorIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
			<path
				d="M12.0004 16.7984C12.25 16.7984 12.4624 16.7108 12.6376 16.5356C12.8128 16.3604 12.9004 16.148 12.9004 15.8984C12.9004 15.6488 12.8128 15.4364 12.6376 15.2612C12.4624 15.086 12.25 14.9984 12.0004 14.9984C11.7508 14.9984 11.5384 15.086 11.3632 15.2612C11.188 15.4364 11.1004 15.6488 11.1004 15.8984C11.1004 16.148 11.188 16.3604 11.3632 16.5356C11.5384 16.7108 11.7508 16.7984 12.0004 16.7984ZM11.1004 13.1984H12.9004V7.19844H11.1004V13.1984ZM12.0004 21.5984C10.6836 21.5984 9.44199 21.3484 8.27559 20.8484C7.10919 20.3484 6.08839 19.6608 5.21319 18.7856C4.33799 17.9104 3.65039 16.8896 3.15039 15.7232C2.65039 14.5568 2.40039 13.3152 2.40039 11.9984C2.40039 10.6648 2.65039 9.41884 3.15039 8.26044C3.65039 7.10284 4.33799 6.08644 5.21319 5.21124C6.08839 4.33604 7.10919 3.64844 8.27559 3.14844C9.44199 2.64844 10.6836 2.39844 12.0004 2.39844C13.334 2.39844 14.58 2.64844 15.7384 3.14844C16.896 3.64844 17.9124 4.33604 18.7876 5.21124C19.6628 6.08644 20.3504 7.10284 20.8504 8.26044C21.3504 9.41884 21.6004 10.6648 21.6004 11.9984C21.6004 13.3152 21.3504 14.5568 20.8504 15.7232C20.3504 16.8896 19.6628 17.9104 18.7876 18.7856C17.9124 19.6608 16.896 20.3484 15.7384 20.8484C14.58 21.3484 13.334 21.5984 12.0004 21.5984ZM12.0004 19.7984C14.1668 19.7984 16.0084 19.04 17.5252 17.5232C19.042 16.0064 19.8004 14.1648 19.8004 11.9984C19.8004 9.83204 19.042 7.99044 17.5252 6.47364C16.0084 4.95684 14.1668 4.19844 12.0004 4.19844C9.83399 4.19844 7.99239 4.95684 6.47559 6.47364C4.95879 7.99044 4.20039 9.83204 4.20039 11.9984C4.20039 14.1648 4.95879 16.0064 6.47559 17.5232C7.99239 19.04 9.83399 19.7984 12.0004 19.7984Z"
				fill="#00807F"
			/>
		</svg>
	);
};

export default ErrorIcon;
