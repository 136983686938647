import { LoadingButton } from '@mui/lab';

const Basebutton = ({ children, onClick, type, disabled, extra }) => {
	return (
		<LoadingButton
			onClick={onClick}
			type={type}
			loading={disabled}
			loadingPosition="end"
			variant="outlined"
			sx={{
				borderRadius: '6px',
				padding: '12px 24px',
				fontFamily: 'Outfit',
				width: '264px',
				color: '#214E92',
				fontSize: '14px',
				fontWeight: '500',
				marginTop: '20px',
				textTransform: 'capitalize',
				'@media (max-width: 1024px)': {
					width: '100%',
				},
				'&:disabled': {
					color: '#aaa',
					background: '#E0E0E0',
					border: '1px solid #e0e0e0',
				},
				...extra,
			}}
		>
			<span>{children}</span>
		</LoadingButton>
	);
};

export default Basebutton;
