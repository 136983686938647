const CheckboxCircle = () => {
	return (
		<svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="Check Box">
				<rect id="Rectangle 430" width={16} height={16} rx={6} fill="#00807F" />
				<path
					id="Vector 5"
					d="M4.5 7.75L7 10.25L11.5 5.75"
					stroke="white"
					strokeWidth={2}
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	);
};

export default CheckboxCircle;
