import CircularProgress from '@mui/material/CircularProgress';
import { Suspense } from 'react';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import { AuthProvider } from './contexts/AuthContext';
import { LanguageProvider } from './contexts/LanguageContext';
import BaseLayout from './layout/BaseLayout';

const queryClient = new QueryClient();

const App = () => {
	return (
		<QueryClientProvider client={queryClient}>
			<LanguageProvider>
				<BrowserRouter>
					<AuthProvider>
						<Suspense
							fallback={
								<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<CircularProgress />
								</div>
							}
						>
							<Toaster
								position="bottom-center"
								reverseOrder={false}
								gutter={8}
								containerStyle={{}}
								toastOptions={{
									className: '',
									duration: 5000,
									style: {
										borderRadius: '10px',
										background: '#333',
										color: '#fff',
									},
								}}
							/>
							<BaseLayout />
						</Suspense>
					</AuthProvider>
				</BrowserRouter>
			</LanguageProvider>
		</QueryClientProvider>
	);
};

export default App;
