/* eslint-disable jsx-a11y/control-has-associated-label */
import { Box } from '@mui/material';
import LocationMarkIcon from '../../assets/svgs/LocationMarkIcon';
import useLanguage from '../../hooks/useLanguage';
import CustomInput from './CustomInput';

const LocationComponent = ({ name, placeholder, callback }) => {
	const { state: currentLanguage } = useLanguage();
	function handleGeoLocation() {
		callback();
	}

	return (
		<Box
			sx={{
				position: 'relative',
			}}
		>
			<CustomInput
				extra={{
					marginTop: 0,
					maxWidth: '100%',
				}}
				placeholder={placeholder}
				type="text"
				name={name}
			/>
			<button
				onClick={handleGeoLocation}
				type="button"
				style={{
					position: 'absolute',
					top: 15,
					right: currentLanguage === 'en' ? '10px' : 'unset',
					left: currentLanguage === 'en' ? 'unset' : '12px',
					cursor: 'pointer',
				}}
			>
				<LocationMarkIcon />
			</button>
		</Box>
	);
};

export default LocationComponent;
