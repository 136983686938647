const BellIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none">
			<g clipPath="url(#clip0_1308_18738)">
				<path
					d="M8.00028 14.4342C8.70742 14.4342 9.28599 13.8556 9.28599 13.1484H6.71456C6.71456 13.8556 7.29314 14.4342 8.00028 14.4342ZM11.8574 10.577V7.36272C11.8574 5.38915 10.8096 3.73701 8.96456 3.29987V2.86272C8.96456 2.32915 8.53385 1.89844 8.00028 1.89844C7.46671 1.89844 7.03599 2.32915 7.03599 2.86272V3.29987C5.19742 3.73701 4.14314 5.38272 4.14314 7.36272V10.577L2.85742 11.8627V12.5056H13.1431V11.8627L11.8574 10.577ZM10.5717 11.2199H5.42885V7.36272C5.42885 5.76844 6.39956 4.46987 8.00028 4.46987C9.60099 4.46987 10.5717 5.76844 10.5717 7.36272V11.2199Z"
					fill="#00807F"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1308_18738">
					<rect
						width="15.4286"
						height="15.4286"
						fill="white"
						transform="translate(0.285156 0.289062)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default BellIcon;
