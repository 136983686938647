import { useEffect, useRef, useState } from 'react';
import { socketBaseURL } from './config/baseURL';

export const useWebSocket = () => {
	const ws = useRef(null);
	const [val, setVal] = useState(null);

	useEffect(() => {
		console.log('WebSocket initialization');
		const socket = new WebSocket(socketBaseURL());

		socket.onmessage = (event) => setVal(event.data);

		socket.onopen = () => {
			console.log('WebSocket connected');
		};

		socket.onclose = () => {
			console.log('WebSocket disconnected');
		};
		ws.current = socket;

		return () => {
			socket.close();
		};
	}, []);

	return [val, ws.current?.send.bind(ws.current)];
};
