export const formInputStyles = {
	height: '48px',
	maxWidth: '480px',
	width: '100%',
	marginTop: '30px',
	marginBottom: '10px',
	borderRadius: '6px',
	padding: '0 24px',
	border: '1px solid #EFF1F3',
	borderBottom: '1px solid #EFF1F3',
	'&:hover': {
		border: '1px solid #e0e0e0',
	},
	'@media (max-width: 1024px)': {
		maxWidth: '100%',
	},
	'@media (max-width: 480px)': {
		marginTop: '15px',
		padding: '0 18px',
	},
};
export const errorMsgStyle = {
	color: 'red',
	// marginTop: '10px',
	width: '100%',
	maxWidth: '480px',
	textAlign: 'left',
	marginLeft: 'auto',
	marginRight: 'auto',
	fontSize: '0.75rem', // Set your desired font size
	height: '1rem', // Set a fixed height for the error message container
	display: 'flex',
	alignItems: 'center',
};

// export const errorMsgStyle = {
// 	color: 'red',
// 	marginTop: '10px',
// 	width: '100%',
// 	maxWidth: '480px',
// 	textAlign: 'left',
// 	marginLeft: 'auto',
// 	marginRight: 'auto',
// 	// color: '#f44336', // Set your desired error text color
// 	fontSize: '0.75rem', // Set your desired font size
// 	// marginTop: '5px', // Adjust the top margin as needed
// 	height: '1rem', // Set a fixed height for the error message container
// 	display: 'flex',
// 	alignItems: 'center',
// };

export const ReviewModalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 480,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
	'@media (max-width: 320px)': {
		width: 200,
	},
	'@media (min-width: 320px)': {
		width: 200,
	},
	'@media (min-width: 475px)': {
		width: 300,
	},
	'@media (min-width: 768px)': {
		width: 480,
	},
};
