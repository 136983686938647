import { Box } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { forwardRef } from 'react';
import { NavLink as NavLinkBase } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import BookmarkIcon from '../../assets/svgs/BookmarkIcon';
import DashboardIcon from '../../assets/svgs/DashboardIcon';
import ErrorIcon from '../../assets/svgs/ErrorIcon';
import WorkIcon from '../../assets/svgs/WorkIcon';

const classes = {
	activeLink: 'active-link',
	link: 'link',
};

// eslint-disable-next-line react/display-name
const NavLink = forwardRef((props, ref) => {
	return (
		<NavLinkBase
			ref={ref}
			className={(navData) =>
				`${navData.isActive ? classes.activeLink : classes.link} ${props.className}`
			}
			to={props.to}
		>
			{props.children}
		</NavLinkBase>
	);
});

const Sidebar = () => {
	const { t } = useTranslation();
	return (
		<Box
			sx={{
				padding: '10px',
			}}
		>
			<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', marginTop: '5px' }}>
				<ListItem sx={{ color: 'black' }} component={NavLink} to="/dashboard">
					<ListItemIcon sx={{ minWidth: '0px' }}>
						<DashboardIcon sx={{ color: '#00807F' }} />
					</ListItemIcon>
					<ListItemText className="Outfit" sx={{ marginLeft: '1rem' }} primary={t('Dashboard')} />
				</ListItem>

				<ListItem sx={{ color: 'black' }} component={NavLink} to="/job-posts">
					<ListItemIcon sx={{ minWidth: '0px' }}>
						<WorkIcon sx={{ color: '#00807F' }} />
					</ListItemIcon>
					<ListItemText className="Outfit" sx={{ marginLeft: '1rem' }} primary={t('Job posts')} />
				</ListItem>

				<ListItem sx={{ color: 'black' }} component={NavLink} to="company-profile">
					<ListItemIcon sx={{ minWidth: '0px' }}>
						<WorkIcon sx={{ color: '#00807F' }} />
					</ListItemIcon>
					<ListItemText
						className="Outfit"
						sx={{ marginLeft: '1rem' }}
						primary={t('Company Profile')}
					/>
				</ListItem>

				<ListItem to="/favorites" sx={{ color: 'black' }} component={NavLink}>
					<ListItemIcon sx={{ minWidth: '0px' }}>
						<BookmarkIcon sx={{ color: '#00807F' }} />
					</ListItemIcon>
					<ListItemText className="Outfit" sx={{ marginLeft: '1rem' }} primary={t('Favorites')} />
				</ListItem>

				<ListItem to="/report-issue" sx={{ color: 'black' }} component={NavLink}>
					<ListItemIcon sx={{ minWidth: '0px' }}>
						<ErrorIcon sx={{ color: '#00807F' }} />
					</ListItemIcon>
					<ListItemText
						className="Outfit"
						sx={{ marginLeft: '1rem' }}
						primary={t('Report An Issue')}
					/>
				</ListItem>
			</List>
		</Box>
	);
};

export default Sidebar;
