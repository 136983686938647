const BookmarkIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
			<mask
				id="mask0_3873_6505"
				style={{ maskType: 'alpha' }}
				maskUnits="userSpaceOnUse"
				x={0}
				y={0}
				width={24}
				height={24}
			>
				<rect width={24} height={24} fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_3873_6505)">
				<path
					d="M5.5 20.25V5.3C5.5 4.8 5.675 4.375 6.025 4.025C6.375 3.675 6.8 3.5 7.3 3.5H16.7C17.2 3.5 17.625 3.675 17.975 4.025C18.325 4.375 18.5 4.8 18.5 5.3V20.25L12 17.45L5.5 20.25ZM7 17.95L12 15.8L17 17.95V5.3C17 5.23333 16.9667 5.16667 16.9 5.1C16.8333 5.03333 16.7667 5 16.7 5H7.3C7.23333 5 7.16667 5.03333 7.1 5.1C7.03333 5.16667 7 5.23333 7 5.3V17.95Z"
					fill="#00807F"
				/>
			</g>
		</svg>
	);
};

export default BookmarkIcon;
