import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { fetchNewAccessToken } from '../authAPIs';
import { setAccessToken, setRefreshToken } from '../../utils/cookies';

export const errorHandler = (error) => {
	const statusCode = error.response?.status;

	if (statusCode && statusCode !== 401) {
		console.error(error);
	}

	return Promise.reject(error);
};

let isRequestingNewToken = false;

export const refreshTokenHandler = async (error) => {
	const originalRequest = error.config;

	if (error.response.status === 401 && !isRequestingNewToken) {
		isRequestingNewToken = true;
		const response = await fetchNewAccessToken();

		setAccessToken(response.access_token);
		setRefreshToken(response.refresh_token);

		isRequestingNewToken = false;
		axios.defaults.headers.common.Authorization = `Bearer ${response.access_token}`;
		return axios(originalRequest);
	}

	return Promise.reject(error);
};
