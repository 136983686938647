import axios from 'axios';
import toast from 'react-hot-toast';
import { jwtDecode } from 'jwt-decode';

import { i18n } from '../i18n/config';
import { getAccessToken, getRefreshToken, setAccessToken, setRefreshToken } from '../utils/cookies';
// eslint-disable-next-line import/no-cycle
import api, { baseURL } from './config/axiosInstance';

export const authAxiosInstance = axios.create({
	baseURL: `${baseURL}`,
	timeout: 30000,
});

const authAPI = {
	async fetchNewAccessToken() {
		const refresh = getRefreshToken();
		const response = await authAxiosInstance.post('/user/refresh_token/', {
			refresh_token: refresh,
		});
		if (![200, 201].includes(response.status)) {
			toast.error(i18n.t('Internal server error, Please try again later.'));
		}
		return response.data;
	},

	async registerOrSignUp(data) {
		try {
			const response = await authAxiosInstance.post('/user/signup/', data);
			return response.data;
		} catch (error) {
			if (error.response?.status === 400 || error.response?.status === 401) {
				toast.error(i18n.t(error.response.data.errors[0].message));
			} else {
				toast.error(i18n.t('Internal Server Error. We are working on it'));
			}

			throw error;
		}
	},

	async fetchTokensOrSignIn(data) {
		const response = await authAxiosInstance.post('/user/login/', data, {
			validateStatus: (status) => [200, 201, 400, 401, 403, 404].includes(status),
		});
		if ([200, 201].includes(response.status)) {
			const { access_token, token_type, scope, refresh_token } = response.data;

			const payload = jwtDecode(access_token);
			if (payload.employer === undefined || payload.employer === false) {
				throw new Error(i18n.t('Selected account has no permission to sign in'));
			}
			localStorage.setItem(
				'profile',
				JSON.stringify({ access_token, token_type, scope, refresh_token }),
			);

			if (access_token && refresh_token) {
				setAccessToken(access_token, { path: '/' });
				setRefreshToken(refresh_token, { path: '/' });
			}

			toast.success(i18n.t('Successfully signed in'));
		}
		if (response.status === 400) {
			throw new Error(i18n.t('Invalid login or password'));
		}
		if (response.status === 403) {
			throw new Error(
				i18n.t(
					'Your account is not activated. Follow the instructions sent to your email address.',
				),
			);
		}
		if (response.status === 404) {
			throw new Error(i18n.t('Invalid login or password'));
		}
		if (![200, 201].includes(response?.status)) {
			toast.error('Internal server error.We are working on it');
			throw new Error(response.data.error[0].message);
		}

		return response.data;
	},

	async submitOneTimePassword(data) {
		const response = await authAxiosInstance.post('/verify-otp', data);

		if ([200, 201].includes(response.status)) {
			toast.success(i18n.t('Successfully OTP submitted'));
		}

		return response.data;
	},

	async submitForgotPasswordEmail(data) {
		const response = await authAxiosInstance.post('/user/password_reset_request/', data);

		if ([200, 201].includes(response.status)) {
			toast.success(i18n.t('Successfully sent reset password OTP'));
		}

		return response.data;
	},

	async otpResend(data) {
		const token = getAccessToken();
		const response = await authAxiosInstance.post('/user/verify/', data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		if ([200, 201].includes(response.status)) {
			toast.success(i18n.t('OTP verified successfully'));
		}

		return response.data;
	},

	async changeUserPassword(data) {
		const response = await api.post('/auth/password-change/', data);

		if ([200, 201].includes(response.status)) {
			toast.success(i18n.t('Password successfully changed'));
		}

		return response.data;
	},

	async submitNewPassword(data) {
		const response = await authAxiosInstance.post('/user/password_reset/', data);

		if ([200, 201].includes(response.status)) {
			toast.success(i18n.t('Successfully reset password'));
		}

		return response.data;
	},

	async changePassword(data) {
		try {
			const response = await api.post(`/user/password_change/`, data);

			if ([200, 201].includes(response?.status)) {
				toast.success(response?.data?.detail);
			}

			return response?.data;
		} catch (error) {
			return error;
		}
	},

	async verifyAccount(request) {
		try {
			const response = await authAxiosInstance.post('/otp-verification/verify-account/', request);

			if (response.status === 200) {
				toast.success(i18n.t('Account verified successfully'));
			}

			return response.data;
		} catch (error) {
			if (error.response.status === 404) {
				toast.error(i18n.t("Can't find user"));
			}

			if (error.response.status === 500) {
				toast.error(i18n.t('Internal server error.We are working on it'));
			}

			throw error;
		}
	},
};

export const {
	changePassword,
	fetchNewAccessToken,
	fetchTokensOrSignIn,
	registerOrSignUp,
	submitOneTimePassword,
	submitForgotPasswordEmail,
	submitNewPassword,
	changeUserPassword,
	otpResend,
	verifyAccount,
} = authAPI;

export default authAPI;
