/* eslint-disable react/jsx-props-no-spreading */
import { Box, Input } from '@mui/material';
import { useField } from 'formik';

import { useTranslation } from 'react-i18next';
import { errorMsgStyle, formInputStyles } from '../../utils/styles';

const CustomInput = ({
	placeholder,
	errorExtra,
	extra,
	fullWidth,
	readOnly,
	title,
	disabled,
	...props
}) => {
	const { t } = useTranslation();
	const [field, meta] = useField(props);

	return (
		<>
			<Input
				title={title}
				variant="outlined"
				disableUnderline
				autofill="off"
				disabled={disabled}
				sx={{
					...formInputStyles,
					...extra,
					color: readOnly ? '#8E9AA6' : 'inherit',
					'::placeholder': { color: '#8E9AA6' },
				}}
				placeholder={placeholder}
				{...field}
				{...props}
				readOnly={readOnly}
				inputProps={{ style: { color: 'black' } }}
			/>
			{meta.touched && meta.error ? (
				<Box
					sx={{
						...errorMsgStyle,
						maxWidth: fullWidth ? '100%' : '480px',
						marginBottom: '-15px',
						...errorExtra,
						'@media (max-width: 1024px)': {
							marginBottom: '-5px',
						},
					}}
				>
					{t(meta.error)}
				</Box>
			) : null}
		</>
	);
};

export default CustomInput;
