const CheckboxChecked = () => {
	return (
		<svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="Check Box">
				<rect
					id="Rectangle 429"
					x={1}
					y={1}
					width={14}
					height={14}
					rx={5}
					fill="#00807F"
					fillOpacity="0.05"
					stroke="#00807F"
					strokeWidth={2}
				/>
			</g>
		</svg>
	);
};

export default CheckboxChecked;
