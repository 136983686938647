import LogoutIcon from '@mui/icons-material/Logout';
import { Box, Button, MenuItem, Modal, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import CustomMenuItem from '../atoms/CustomMenuItem';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: { xs: '250px', sm: '400px', md: '618px' },
	// height: '300px',
	bgcolor: 'background.paper',
	border: 'none',
	borderRadius: '6px',
	boxShadow: 24,
	padding: '60px 30px',
	paddingBottom: '40px',
};

const LogoutModal = ({ handleLogout }) => {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<>
			<MenuItem
				onClick={handleOpen}
				sx={{
					color: '#FF3A5E',
					fontWeight: 'bold',
					display: 'flex',
					alignItems: 'center',
					fontFamily: 'Outfit',
					gap: '10px',
				}}
			>
				<LogoutIcon />
				<Typography sx={{ color: '#FF3A5E', fontFamily: 'Outfit !important', fontWeight: 'bold' }}>
					{t('Log Out')}
				</Typography>
			</MenuItem>
			{/* <MenuItem
				onClick={handleOpen}
				sx={{
					color: '#FF3A5E',
					fontWeight: 'bold',
					display: 'flex',
					alignItems: 'center',
					gap: '10px',
				}}
				iconSx={{ color: '#FF3A5E' }}
				icon={
					<LogoutIcon
						style={{
							marginTop: '5px',
						}}
					/>
				}
				handleMenuClose={handleClose}
				name={t('Log Out')}
			/> */}
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography
						sx={{ textAlign: 'center', fontFamily: 'Outfit !important' }}
						id="modal-modal-title"
						variant="h6"
						component="h2"
					>
						{t('Are you sure want to logout?')}
					</Typography>

					<Box sx={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '30px' }}>
						<Button
							onClick={handleClose}
							sx={{
								width: '193px',
								height: '48px',
								background: '#214E92',
								fontWeight: '700',
								fontFamily: 'Outfit !important',
								color: 'white',
								border: 'none',
								'&:hover': {
									background: '#214E92',
								},
							}}
						>
							{t('No')}
						</Button>
						<Button
							sx={{
								width: '193px',
								height: '48px',
								background: '#F4F6Fa',
								fontWeight: '700',
								fontFamily: 'Outfit !important',
								color: '#214E92',
								border: 'none',
							}}
							onClick={handleLogout}
						>
							{t('Yes')}
						</Button>
					</Box>
				</Box>
			</Modal>
		</>
	);
};

export default LogoutModal;
